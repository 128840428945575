@import ../../styles/helpers

.charts
    min-height: 480px
    position: relative
    z-index: 2
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1)
    border-radius: 4px
    img
        width: 100%
