@import ../../styles/helpers

.exchange
    min-height: calc(100vh - 88px)
    padding: 4px
    background: #F1F2F4
    +t
        min-height: calc(100vh - 114px)
        padding: 16px 16px 132px
    +dark
        background: $n1

.nav
    display: none
    +t
        display: flex
        margin-bottom: 16px
    +m
        justify-content: space-between

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n3
    transition: all .2s
    &.active
        background: $n3
        color: $n8
        +dark
            background: $n2
            color: $n8
    &:not(:last-child)
        margin-right: 8px
        +m
            margin-right: 0

.row
    display: flex
    margin-top: 4px
    +d
        display: block
        &:after
            content: " "
            display: table
            clear: both
            +t
                display: none

.col
    &:nth-child(2)
        flex-shrink: 0
        width: 300px
        +d
            float: left
        +t
            float: none
            width: 100%
    &:first-child
        flex-grow: 1
        margin: 0 4px
        +d
            float: right
            width: calc(100% - 304px)
            margin: 0 0 0 4px
        +t
            float: none
            width: 100%
            margin: 0
    &:first-child
        +d
            margin-bottom: 4px
        +t
            margin-bottom: 0

.wrapper
    width: 100%
    display: flex
    > :first-child
        flex-grow: 1
        margin: 0 4px
    > :nth-child(2)
        width: 320px
