// Variables for customization
$width: 40px
$height: 20px
$switch-bg-color: #ccc
$switch-checked-bg-color: #2196F3
$slider-size: 12px
$slider-bg-color: white
$transition-duration: .4s

.switch
    position: relative
    display: inline-block
    width: $width
    height: $height

.switch input
    opacity: 0
    width: 0
    height: 0

.slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: $switch-bg-color
    transition: $transition-duration

    &:before
        position: absolute
        content: ""
        height: $slider-size
        width: $slider-size
        left: 4px
        bottom: 4px
        background-color: $slider-bg-color
        transition: $transition-duration

input:checked + .slider
    background-color: $switch-checked-bg-color

input:focus + .slider
    box-shadow: 0 0 1px $switch-checked-bg-color

input:checked + .slider:before
    transform: translateX($width - $slider-size - 8px) // 8px is the padding (4px * 2)

.slider.round
    border-radius: $height

    &:before
        border-radius: 50%
